import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    isOpen: false,
    lead: null,
    leadRaw: null,
    content: null,
    contentRaw: null,
  },
  actions: {
    close(context) {
      context.commit('setIsOpen', false);
      context.commit('setLead', null);
      context.commit('setLeadRaw', null);
      context.commit('setContent', null);
      context.commit('setContentRaw', null);
    },
    toggle(context, content) {
      if (context.state.isOpen) {
        return context.dispatch('close');
      }

      context.commit('setRawState', {
        ...content,
        isOpen: true,
      });
    },
  },
  getters: { getField },
  mutations: {
    updateField,
    setIsOpen: (s, v) => {
      s.isOpen = v;
    },
    setLead: (s, v) => {
      s.lead = v;
    },
    setLeadRaw: (s, v) => {
      s.leadRaw = v;
    },
    setContent: (s, v) => {
      s.content = v;
    },
    setContentRaw: (s, v) => {
      s.contentRaw = v;
    },
    setRawState: (s, v) => {
      if (v.lead) {
        s.lead = v.lead;
      }
      if (v.leadRaw) {
        s.leadRaw = v.leadRaw;
      }
      if (v.content) {
        s.content = v.content;
      }
      if (v.contentRaw) {
        s.contentRaw = v.contentRaw;
      }

      s.isOpen = v.isOpen;
    },
  },
};
