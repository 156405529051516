import apiRoutes from '../../router/api';
import { sendGetRequest } from './Hydra';

export default {
  getSport(id) {
    // Test required values
    if (!id) {
      throw new Error('Product id required');
    }

    const uri = new URL(apiRoutes.sports.get(id), window.location.origin);
    return sendGetRequest(uri);
  },
};
