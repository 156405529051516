/* eslint-disable no-underscore-dangle */
import SubmissionError from '../../../error/SubmissionError';
import fetch from '../../../helpers/fetch';
import * as types from './mutation_types';

export const create = ({ commit }, { route, values }) => {
  commit(types.CONTACT_CREATE_SET_ERROR, '');
  commit(types.CONTACT_CREATE_SET_VIOLATIONS, []);
  commit(types.CONTACT_CREATE_TOGGLE_LOADING);

  return fetch(route, { method: 'POST', body: JSON.stringify(values) })
    .then((response) => {
      commit(types.CONTACT_CREATE_TOGGLE_LOADING);

      return response.json();
    })
    .then((data) => {
      commit(types.CONTACT_CREATE_SET_CREATED, data);
      return data.contact;
    })
    .catch((e) => {
      commit(types.CONTACT_CREATE_TOGGLE_LOADING);

      if (e instanceof SubmissionError) {
        const err = { ...e.errors };
        delete err._error;
        commit(types.CONTACT_CREATE_SET_ERROR, e.errors._error);
        commit(types.CONTACT_CREATE_SET_VIOLATIONS, err);
        // eslint-disable-next-line

        return;
      }

      commit(types.CONTACT_CREATE_SET_ERROR, e.message);
    });
};
