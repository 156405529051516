import { getField } from 'vuex-map-fields';

export default {
  getField,
  incompleteBookings(state) {
    return state.bookings.filter(
      (b) => ['waiting_players', 'waiting_payment'].includes(b.status) && b.date_from > new Date()
    );
  },
  upcomingConfirmedBookings(state) {
    return state.bookings.filter((b) => ['confirmed'].includes(b.status) && b.date_to > new Date());
  },
  activeSubscriptions(state) {
    return state.subscriptions.filter(
      (b) => (!b.date_to || b.date_to > new Date()) && b.status === 'confirmed'
    );
  },
  activeCards(state) {
    return state.cards.filter((b) => b.status === 'confirmed');
  },
};
