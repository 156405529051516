import { editProfile, getUser } from '../../services/api/User';
import { get as getContact } from '../../services/api/Contact';
import SubmissionError from '../../error/SubmissionError';
import UnprocessableEntityError from '../../error/UnprocessableEntityError';

// const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export default {
  loadUser: async (context, id) => {
    try {
      context.commit('setIsLoading', true);
      const response = await getUser(id);
      // We gonna show only the accesses
      context.commit('setUser', response);
    } catch (e) {
      console.error(e);
      context.commit('setError', e.name);
    }
    context.commit('setIsLoading', false);
  },
  loadContact: async (context, id) => {
    try {
      context.commit('setIsLoading', true);
      const response = await getContact(id);
      context.commit('setContact', response);
    } catch (e) {
      console.error(e);
      context.commit('setError', e.name);
    }
    context.commit('setIsLoading', false);
  },
  editProfile: async (context, { id, mobile }) => {
    try {
      context.commit('setIsLoading', true);

      const response = await editProfile(id, {
        mobile,
      });

      context.commit('setUser', response);
    } catch (e) {
      if (e instanceof UnprocessableEntityError || e instanceof SubmissionError) {
        throw e;
      } else {
        console.error(e);
        context.commit('setError', e.name);
      }
    }
    context.commit('setIsLoading', false);
  },
};
