/* eslint-disable no-param-reassign */
import { mutations } from './constants';

export default {
  [mutations.SET_CONTACTS](state, contacts) {
    state.contacts = contacts;
  },
  [mutations.SET_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
  [mutations.SET_ERROR](state, error) {
    state.error = error;
  },
  [mutations.SET_TOTAL](state, total) {
    state.total = total;
  },
};
