import { sendGetRequest } from './Hydra';
import routes from '../../router/api';
import { objToQueryString } from './helpers';

export async function getAvailabilities(productId, options) {
  const url = new URL(routes.fieldRental.availabilities(productId), window.location.origin);
  url.search = objToQueryString(options, '');
  const content = await sendGetRequest(url, {
    Accept: 'application/json',
  });
  // http://sport-finder.local/api/field_rentals/6/availabilities?sport=68&date=2021-07-07

  return content.results.map((item) => {
    // eslint-disable-next-line no-param-reassign
    item.start = new Date(item.start);
    // eslint-disable-next-line no-param-reassign
    item.end = new Date(item.end);
    return item;
  });
}

export async function getRentals(options) {
  const url = new URL(routes.fieldRental.fetch, window.location.origin);
  url.search = objToQueryString(options, '');

  // console.debug('FieldRental/getRentals', options, url);
  const response = await sendGetRequest(url);

  // console.debug(response);
  return response;
}

export async function getOverallAvailabilities(resourceId, options) {
  const url = new URL(routes.fieldRental.overallAvailabilities(resourceId), window.location.origin);
  url.search = objToQueryString(options, '');
  // http://sport-finder.local/api/field_rentals/6/overall_availabilities?sport=68&date=2021-07-07
  const content = await sendGetRequest(url, {
    Accept: 'application/json',
  });

  return (Array.isArray(content) ? content : content.results).map((item) => {
    // eslint-disable-next-line no-param-reassign
    item.start = new Date(item.start);
    // eslint-disable-next-line no-param-reassign
    item.end = new Date(item.end);
    return item;
  });
}
