export function objToQueryString(obj, prefix) {
  // console.debug('objToQueryString', obj, prefix);

  let fields = [];
  const keys = Object.keys(obj);
  if (obj && keys.length) {
    keys
      .filter((key) => obj[key] !== null && obj[key] !== undefined) // Check that value is ok
      .forEach((key) => {
        // Null value
        if (obj[key] === null || obj[key] === undefined) {
          fields.push(`${prefix ? `${prefix}[${key}]` : key}=`);
        }
        // Value is array, recursive
        else if (Array.isArray(obj[key])) {
          const arrResult = obj[key].map((v, j) =>
            typeof v === 'object' ? objToQueryString(v, `${key}[${j}]`) : `${key}[${j}]=${v}`
          );
          // console.dir(obj[key], arrResult);
          fields = fields.concat(arrResult);
        }
        // Value is an object
        else if (typeof obj[key] === 'object') {
          fields.push(objToQueryString(obj[key], prefix ? `${prefix}[${key}]` : key));
        }
        // Other
        else {
          fields.push(
            `${prefix ? `${prefix}[${key}]` : key}=${typeof obj[key] === 'undefined' ? '' : encodeURIComponent(obj[key])}`
          );
        }
      });
  }
  // No keys but prefix
  else if (prefix) {
    fields.push(`${prefix}=`);
  }

  // console.info('objToQueryString.fields', fields);

  return fields.join('&');
}
