import CalendarEvent from './CalendarEvent';

export default class FieldAvailabilityEvent extends CalendarEvent {
  constructor(ResourceAvailability) {
    super(ResourceAvailability);

    this.count = ResourceAvailability.count;
    this.resource = ResourceAvailability.resource;
    this.totalFields = ResourceAvailability.totalFields;
  }

  get availability() {
    if (!this.count) {
      return 'full';
    }

    const total = this.totalFields ?? 1;

    return this.count === 1 && total > 1 ? 'last' : 'available';
  }

  computedCssClass() {
    return `${this.class || ''} event--fieldavailability availability--${this.availability}`;
  }
}
