import SubmissionError from '../error/SubmissionError';

// Originally present in a config file
// @todo: check for potential security implication of using this. Not a high priority.
const ENTRYPOINT = window.location.origin;

const MIME_TYPE = 'application/ld+json';

const transformRelationToIri = (payload) => {
  const innerP = payload;
  payload.forEach((value, property) => {
    if (typeof value === 'object' && value['@id'] !== undefined) {
      innerP[property] = value['@id'];
    }

    if (Array.isArray(value)) innerP[property] = transformRelationToIri(value);
  });

  return innerP;
};

export default function fetch(id, options = {}) {
  // eslint-disable-next-line no-param-reassign
  if (typeof options.headers === 'undefined') options.headers = new Headers();

  if (options.headers.get('Accept') === null) options.headers.set('Accept', MIME_TYPE);

  if (
    options.body !== 'undefined' &&
    !(options.body instanceof FormData) &&
    options.headers.get('Content-Type') === null
  )
    options.headers.set('Content-Type', MIME_TYPE);

  const payload = options.body && JSON.parse(options.body);
  // eslint-disable-next-line no-param-reassign
  if (typeof payload === 'object' && payload['@id'])
    options.body = JSON.stringify(transformRelationToIri(payload));

  return window.fetch(new URL(id, ENTRYPOINT), options).then((response) => {
    if (response.ok) return response;

    return response.json().then((json) => {
      /* Block adapted from api-platform code to Spartan's custom format (not using hydra) */
      const error = json.errors.detail || response.statusText;

      if (!json.errors || !json.errors.violations) throw Error(error);

      const errors = { _error: error };
      json.errors.violations.forEach((violation) => {
        errors[violation.propertyPath] = violation.title;
      });

      /* endModifiedBlock */
      throw new SubmissionError(errors, response.status);
    });
  });
}
