import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isMapLoading: false,

    // Data
    mapResults: [],
    results: [],

    // Pagination
    paginator: {
      count: 0,
      page: 1,
      page_count: 0,
      limit: 15,
    },

    // Data from the geocoder
    geocoding: null,

    // Data from the map
    bounds: null,

    sport: null,
    // predefinedSearch: false,

    filters: {
      date: null,
      distance: 10,
      level: ['n/a'],
      gender: null,
    },

    zone: null,
    zoneName: '',

    selectedTimeSlot: null,
    selectedTimeSlotMatches: [],
    selectedMatch: null,

    // Override
    ...window.SportFinder.store.match,
  },
  actions,
  getters,
  mutations,
};
