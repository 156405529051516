import { getters } from './constants';

export default {
  [getters.ACCESS_HISTORY]: (state) => state.accessHistory,
  [getters.IS_LOADING]: (state) => state.isLoading,
  [getters.SCAN_HISTORY]: (state) => state.scanHistory,
  [getters.SCAN_RESULT]: (state) => state.scanResult,
  [getters.VIOLATIONS]: (state) => state.violations,
  [getters.ERROR]: (state) => state.error,
  [getters.IS_BOOKING_NOT_FOUND]: (state) => state.isBookingNotFound,
};
