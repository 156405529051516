import { getField } from 'vuex-map-fields';
import { getters } from './constants';

export default {
  getField,
  [getters.ID]: (state) => state.id,
  [getters.IS_LOADING]: (state) => state.isLoading,
  [getters.OPENING_HOURS]: (state) => state.openingHours,
  [getters.SPORTS]: (state) => state.sports,
  [getters.RESOURCES]: (state) => state.resources,
};
