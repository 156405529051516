import fetchApi, { sendPxxxRequest } from './Hydra';
import routes from '../../router/api';

export async function getUser(id) {
  const uri = new URL(routes.user.get(id), window.location.origin);

  const response = await fetchApi(uri);

  return response.json();
}

export async function editProfile(userId, formData) {
  const uri = new URL(routes.user.editProfile(userId), window.location.origin);

  const response = await sendPxxxRequest(uri, formData, 'PATCH');

  return response;
}
