const prefixValue = '/api';
const prefix = (value) => `${prefixValue}/${value}`;

const routes = {
  asyncActions: {
    get: prefix(`async_actions`),
    put: (id) => prefix(`async_actions/${id}`),
    restart: (id) => prefix(`async_actions/${id}/restart`),
  },
  attribution: {
    get: prefix(`attributions`),
    put: (id) => prefix(`attributions/${id}`),
  },
  center: {
    get: (id) => prefix(`centers/${id}`),
    search: prefix('centers'),
    searchMap: prefix('centers/map'),
  },
  customer: {
    create: (locale) => `${prefix(locale)}/partner/customer/create`,
  },
  favorites: {
    collection: `${prefix('user_center_favorites')}`,
    item: (id) => `${prefix('user_center_favorites')}/${id}`,
  },
  entrancePurchase: {
    create: `${prefix('entrance_purchase/create')}`,
  },
  entrance: {
    computePrice: (productId) => `${prefix(`entrance/${productId}/compute_price`)}`,
    initiateOnlinePayment: (purchaseId) =>
      prefix(`entrance_purchases/${purchaseId}/initiate_online_payment`),
  },
  groups: prefix('groups'),
  levelSet: {
    get: (id) => prefix(`level_sets${id ? `/${id}` : ''}`),
  },
  options: {
    get: (id = null) => `${prefix('options')}${id ? `/${id}` : ''}`,
  },
  subscriptionTypesByPartner: (partnerId) =>
    prefix(`subscriptions.json?partner=${encodeURIComponent(partnerId)}`),
  contacts: {
    getAll: `${[prefix('contacts')]}`,
    get: (id) => prefix(`contacts/${id}`),
    export: prefix(`contacts/export`),
    merge: prefix(`contacts/merge`),
  },
  onboarding: {
    getAll: prefix('onboardings'),
    get: (id) => prefix(`onboardings/${id}`),
    process: (id) => prefix(`onboardings/${id}/process`),
  },
  paymentIntent: (id) => prefix(`payment_intents/${id}`),
  payments: {
    fetch: prefix(`payments`),
    export: prefix(`payments/export`),
  },
  products: {
    fetch: prefix('products'),
    get: (productId) => `${prefix('products')}/${productId}`,
    configuration: (productId) => `${prefix('products')}/${productId}/configurations`,
  },
  purchases: {
    get: (id) => `/api/purchases/${id}`,
    configuration: (purchaseId) => `${prefix('purchases')}/${purchaseId}/configurations`,
    paymentMethods: (purchaseId) => `${prefix('purchases')}/${purchaseId}/payment_methods`,
    export: {
      csv: prefix('purchases/export/csv'),
      pdf: prefix('purchases/export/pdf'),
    },
  },
  payouts: prefix('payouts'),
  recurrentPurchase: prefix('recurrent_purchases'),
  timeSlots: prefix('time_slots'),
  timeSlot: (id) => `${prefix('time_slots')}/${id}`,
  fieldRental: {
    fetch: prefix('field_rentals'),
    availabilities: (productId) => prefix(`field_rentals/${productId}/availabilities`),
    overallAvailabilities: (resourceId) =>
      prefix(`field_rentals/${resourceId}/overall_availabilities`),
  },
  fieldRentalPurchase: {
    acceptInvitation: (id) => prefix(`field_rental_purchases/${id}/accept`),
    cancelBooking: (id) => prefix(`field_rental_purchases/${id}/cancel_booking`),
    cancelInvitation: (id) => prefix(`field_rental_purchases/${id}/cancel_invitation`),
    cancelReplacement: (id) => prefix(`field_rental_purchases/${id}/cancel_replacement`),
    cancelParticipation: (purchaseId) =>
      prefix(`field_rental_purchases/${purchaseId}/cancel_participation`),
    configure: (id) => prefix(`field_rental_purchases/${id}/configure`),
    confirmInvitation: (purchaseId) => prefix(`field_rental_purchases/${purchaseId}/confirm`),
    declineInvitation: (purchaseId) => prefix(`field_rental_purchases/${purchaseId}/decline`),
    get: (id) => prefix(`field_rental_purchases/${id}`),
    getCollection: prefix('field_rental_purchases'),
    initiateOnlinePayment: (purchaseId) =>
      prefix(`field_rental_purchases/${purchaseId}/initiate_online_payment`),
    invite: (id) => prefix(`field_rental_purchases/${id}/invite`),
    matches: prefix('matches'),
    payOnSite: (id) => prefix(`field_rental_purchases/${id}/pay_onsite`),
    payWithCard: (id) => prefix(`field_rental_purchases/${id}/pay_with_card`),
    payWithPrepaidCard: (id) => prefix(`field_rental_purchases/${id}/pay_with_card`),
    payWithWallet: (id) => prefix(`field_rental_purchases/${id}/pay_with_wallet`),
    post: prefix('field_rental_purchases'),
    put: (id) => prefix(`field_rental_purchases/${id}`),
    refuse: (id) => prefix(`field_rental_purchases/${id}/refuse`),
    request: (id) => prefix(`field_rental_purchases/${id}/request`),
    cancelRequest: (id) => prefix(`field_rental_purchases/${id}/cancel_request`),
  },
  sports: {
    get: (id) => (id.startsWith('/api/sports') ? id : prefix(`sports/${id}`)),
  },
  user: {
    get: (id = null) => prefix(`users${id ? `/${id}` : ''}`),
    editProfile: (id) => prefix(`users/${id}/edit-profile`),
  },
  virtualCreditPurchases: {
    get: prefix('virtual_credit_purchases'),
    initiateOnlinePayment: (purchaseId) =>
      prefix(`virtual_credit_purchases/${purchaseId}/initiate_online_payment`),
  },
  wallets: prefix('wallets'),
  zone: {
    search: prefix('zones'),
  },
};

export default routes;
