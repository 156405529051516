import { actions, mutations } from './constants';
import { getAll } from '../../../services/api/Contact';
import { getters as filterGetters } from '../filters/constants';

export default {
  async [actions.LOAD_CONTACTS]({ commit, rootGetters }, { partnerId, centerId }) {
    commit(mutations.SET_IS_BUSY, true);

    // Get filters from their store
    const searchParameters = rootGetters[`contact/filters/${filterGetters.URL_SEARCH_PARAMETERS}`];
    const requestOptions = { partner: partnerId, center: centerId, ...searchParameters };

    const response = await getAll(requestOptions);

    commit(mutations.SET_CONTACTS, response['hydra:member']);
    commit(mutations.SET_TOTAL, response['hydra:totalItems']);
    commit(mutations.SET_IS_BUSY, false);
  },
};
