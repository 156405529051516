import { getters } from './constants';

export default {
  [getters.CONTACTS](state) {
    return state.contacts;
  },
  [getters.IS_BUSY](state) {
    return state.isBusy;
  },
  [getters.ERROR](state) {
    return state.error;
  },
  [getters.TOTAL](state) {
    return state.total;
  },
};
