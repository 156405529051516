import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  setPurchase(state, purchase) {
    const formattedPurchase = {
      ...purchase,
      date_from: !purchase.date_from ? purchase.date_from : new Date(purchase.date_from),
      date_to: !purchase.date_to ? purchase.date_to : new Date(purchase.date_to),
    };
    state.purchase = formattedPurchase;
  },
  updatePurchase(state, purchase) {
    state.purchase = {
      ...state.purchase,
      ...purchase,
      date_from: !purchase.date_from ? purchase.date_from : new Date(purchase.date_from),
      date_to: !purchase.date_to ? purchase.date_to : new Date(purchase.date_to),
    };
  },
  setProduct(state, product) {
    state.product = product;
  },
  setConfiguration(state, configuration) {
    state.configuration = configuration;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setOptions(state, options) {
    state.options = options;
  },
  setPaymentConfig(state, config) {
    state.paymentConfig = config;
  },
  setPaymentIntent(state, intent) {
    state.paymentIntent = intent;
  },
  setPaymentMethods(state, pm) {
    state.paymentMethods = Array.isArray(pm) ? pm : [pm];
  },
};
