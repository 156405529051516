import create from './create';
import select from './select';
import filters from './filters';
import list from './list';

export default {
  namespaced: true,
  modules: {
    create,
    select,
    filters,
    list,
  },
};
