import { getMyPurchases } from '../../services/api/Purchase';

function initPurchases(entity) {
  const formattedEntity = entity;
  formattedEntity.date_from = entity.date_from ? new Date(entity.date_from) : null;
  formattedEntity.date_to = entity.date_to ? new Date(entity.date_to) : null;
  formattedEntity.created_at = entity.created_at ? new Date(entity.created_at) : null;
  return formattedEntity;
}

const prefix0 = (v) => (v < 10 ? `0${v}` : v);

const formatDate = (v) => {
  if (!(v instanceof Date)) {
    // eslint-disable-next-line no-param-reassign
    v = new Date(v);
  }
  return `${v.getFullYear()}-${prefix0(v.getUTCMonth() + 1)}-${prefix0(v.getDate())}`;
};

export default {
  loadBookings: async ({ commit, rootState }) => {
    const sortBy = 'date_from';
    const sortDesc = false;

    try {
      commit('setIsBookingsLoading', true);

      const currentDate = new Date();
      currentDate.setHours(currentDate.getHours() - 3);
      const formattedDate = formatDate(currentDate);

      const response = await getMyPurchases({
        page: 1,
        limit: 25,
        order: sortBy
          ? {
              [sortBy]: sortDesc ? 'DESC' : 'ASC',
            }
          : null,
        'attributions.customer.contact': rootState.user.user?.contact?.id,
        'date_to[after]': formatDate(formattedDate),
        type: ['field_rental', 'entrance'],
      });
      // We gonna show only the accesses
      commit('setBookings', response['hydra:member'].map(initPurchases));
    } catch (e) {
      console.error(e);
      commit('setError', e.name);
    }
    commit('setIsBookingsLoading', false);
  },
  loadSubscriptions: async ({ commit, rootState }) => {
    const sortBy = 'date_from';
    const sortDesc = false;
    try {
      commit('setIsSubscriptionsLoading', true);
      const response = await getMyPurchases({
        page: 1,
        limit: 50,
        order: sortBy
          ? {
              [sortBy]: sortDesc ? 'DESC' : 'ASC',
            }
          : null,
        'attributions.customer.contact': rootState.user.user?.contact?.id,
        type: 'subscription',
      });
      // We gonna show only the accesses
      commit('setSubscriptions', response['hydra:member'].map(initPurchases));
    } catch (e) {
      console.error(e);
      commit('setError', e.name);
    }
    commit('setIsSubscriptionsLoading', false);
  },
  loadCards: async ({ commit, rootState }) => {
    const sortBy = 'created_at';
    const sortDesc = true;
    try {
      commit('setIsCardsLoading', true);
      const response = await getMyPurchases({
        page: 1,
        limit: 50,
        order: sortBy
          ? {
              [sortBy]: sortDesc ? 'DESC' : 'ASC',
            }
          : null,
        'attributions.customer.contact': rootState.user.user?.contact?.id,
        type: 'credit',
      });
      // We gonna show only the accesses
      commit('setCards', response['hydra:member'].map(initPurchases));
    } catch (e) {
      console.error(e);
      commit('setError', e.name);
    }
    commit('setIsCardsLoading', false);
  },
};
