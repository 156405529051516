import { sendPatchRequest, sendGetRequest, objectToQueryString, sendPostRequest } from './Hydra';
import apiRoutes from '../../router/api';
import { objToQueryString } from './helpers';

export async function getAll(options) {
  const selectedOptions = {};
  Object.keys(options)
    .filter((k) => options[k])
    .forEach((k) => {
      selectedOptions[k] = options[k];
    });

  if (!Object.hasOwnProperty.call(selectedOptions, 'page') || !selectedOptions.page) {
    selectedOptions.page = 1;
  }

  // Test required values
  if (!selectedOptions.partner) {
    throw new Error('PartnerId required');
  }
  if (!selectedOptions.center) {
    throw new Error('CenterId required');
  }

  const uri = new URL(apiRoutes.contacts.getAll, window.location.origin);

  // console.info('loadcontacts : selectedOptions', selectedOptions);

  uri.search = objToQueryString(selectedOptions, '');

  return sendGetRequest(uri);
}

export async function patchContact(options) {
  // console.debug('patchContact');
  // console.dir(options);
  const uri = new URL(apiRoutes.contacts.get(options.id), window.location.origin);
  return sendPatchRequest(uri, options);
}

export async function get(id) {
  const uri = new URL(apiRoutes.contacts.get(id), window.location.origin);
  return sendGetRequest(uri);
}

export async function merge(partner, keepContact, deleteContact) {
  const uri = new URL(apiRoutes.contacts.merge, window.location.origin);
  uri.search = objectToQueryString({ partner });
  return sendPostRequest(uri, { keepContact, deleteContact });
}
