import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isBookingNotFound: false,
    accessHistory: [],
    /** @type {ScanAccessLog[]} */
    scanHistory: [],
    accessCode: null,
    /** @type {ScanResult} */
    scanResult: null,
    scanReason: null,
    // Form violations
    violations: null,
    error: null,
  },
  actions,
  getters,
  mutations,
};
