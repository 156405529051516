export const actions = {
  FETCH_REFERENCE: 'FETCH_REFERENCE',
  FETCH_SCAN_HISTORY: 'FETCH_SCAN_HISTORY',
  FETCH_ACCESS_HISTORY: 'FETCH_ACCESS_HISTORY',
};

export const getters = {
  IS_LOADING: 'IS_LOADING',
  SCAN_HISTORY: 'SCAN_HISTORY',
  ACCESS_HISTORY: 'ACCESS_HISTORY',
  SCAN_RESULT: 'SCAN_RESULT',
  VIOLATIONS: 'VIOLATIONS',
  ERROR: 'ERROR',
  IS_BOOKING_NOT_FOUND: 'IS_BOOKING_NOT_FOUND',
};

export const mutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_SCAN_HISTORY: 'SET_SCAN_HISTORY',
  SET_ACCESS_HISTORY: 'SET_ACCESS_HISTORY',
  SET_IS_ACCESS_CODE_NOT_FOUND: 'SET_IS_ACCESS_CODE_NOT_FOUND',
  SET_SCAN_RESULT: 'SET_SCAN_RESULT',
  SET_VIOLATIONS: 'SET_VIOLATIONS',
  SET_ERROR: 'SET_ERROR',
  ADD_SCAN_HISTORY: 'ADD_SCAN_HISTORY',
  ADD_ACCESS_HISTORY: 'ADD_ACCESS_HISTORY',
};
