export const actions = {
  FETCH_CENTER: 'FETCH_CENTER',
  FETCH_OPENING_HOURS: 'FETCH_OPENING_HOURS',
  FETCH_RESOURCES: 'FETCH_RESOURCES',
};

export const getters = {
  OPENING_HOURS: 'OPENING_HOURS',
  IS_LOADING: 'IS_LOADING',
  ID: 'ID',
  RESOURCES: 'RESOURCES',
  SPORTS: 'SPORTS',
};

export const mutations = {
  SET_ADDRESS: 'SET_ADDRESS',
  SET_OPENING_HOURS: 'SET_OPENING_HOURS',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_ID: 'SET_ID',
  SET_RESOURCES: 'SET_RESOURCES',
  SET_SHOWN_RESOURCES: 'SET_SHOWN_RESOURCES',
  SET_SPORTS: 'SET_SPORTS',
};
