import { getField } from 'vuex-map-fields';
import { getters } from './constants';
import { SORT_ASC } from './SortOrders';

export default {
  getField,
  [getters.IS_BUSY](state) {
    return state.isBusy;
  },
  [getters.PAGE](state) {
    return state.page;
  },
  [getters.PER_PAGE](state) {
    return state.limit;
  },
  [getters.SUBSCRIPTION_TYPES](state) {
    return state.subscriptions;
  },
  [getters.SELECTED_SUBSCRIPTIONS](state) {
    return state.selectedSubscriptions;
  },
  [getters.CONTACT_TYPE](state) {
    return state.contactType;
  },
  [getters.SEARCH_TERM](state) {
    return state.searchTerm;
  },
  [getters.SORT_BY](state) {
    return state.sortBy;
  },
  [getters.SORT_ORDER](state) {
    return state.sortOrder;
  },
  [getters.URL_SEARCH_PARAMETERS](state) {
    const toReturn = {
      page: state.page,
      search: state.searchTerm,
    };

    if (state.selectedSubscriptions.length) {
      Object.assign(toReturn, {
        'subscription[id]': state.selectedSubscriptions,
        'subscription[active]': true,
        'subscription[validity]': true,
        'exists[user]': null,
      });
    }
    if (state.contactType !== null) {
      Object.assign(toReturn, {
        type: state.contactType,
      });
    }

    if (state.sortBy) {
      if (['first_name', 'last_name'].includes(state.sortBy)) {
        const mapping = new Map([
          ['first_name', 'firstName'],
          ['last_name', 'lastName'],
        ]);

        Object.assign(toReturn, {
          [`order[${mapping.get(state.sortBy)}]`]: state.sortOrder || SORT_ASC,
        });
      } else {
        // todo: useless without an return for the user ?
        // eslint-disable-next-line no-console
        console.info('You have selected a value not enabled for sorting');
      }
    }

    return toReturn;
  },
};
