import queryString from 'query-string';

export default {
  async computeTotal(purchaseId, payload) {
    const body = JSON.stringify(payload);
    const request = new Request(`/api/purchases/${purchaseId}/compute_total`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      // mode: "cors",
      // cache: "default",
      body,
    });
    const res = await fetch(request);
    const content = await res.json();
    return content.total;
  },

  async getAvailabilities(productId, centerId, options) {
    const queryParams = {
      ...options,
    };

    const string = queryString.stringify(queryParams, { arrayFormat: 'bracket' });
    const url = `/api/field_rentals/${productId}/availabilities?${string}`;
    // http://sport-finder.local/api/field_rentals/6/availabilities?sport=68&date=2021-07-07
    const request = new Request(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    function leadZeroDate(date) {
      const year = date.getFullYear();
      const month = `${date.getMonth() + 1}`.padStart(2, '0');
      const day = `${date.getDate()}`.padStart(2, '0');
      return [year, month, day].join('-');
    }

    const res = await fetch(request);
    const content = await res.json();
    return content.results.map((item) => {
      item.start = new Date(item.start);
      item.end = new Date(item.end);
      // format function is only available w. calendar package
      item.date = Date().format ? item.start.format('YYYY-MM-DD') : leadZeroDate(item.start);
      item.resource = Array.isArray(item.resource)
        ? item.resource.map((r) => r.id)
        : [item.resource.id];
      item.duration = (item.end.valueOf() - item.start.valueOf()) / 1000 / 60;
      item.product = productId;
      return item;
    });
  },
};
