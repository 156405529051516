import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    id: null,
    partnerId: null,
    openingHours: null,
    resources: [],
    sports: [],
    address: null,
    ...window.SportFinder.store.center,
  },
  actions,
  getters,
  mutations,
};
