/* eslint-disable no-param-reassign */
import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setError(state, value) {
    state.error = value;
  },
  setUser(state, value) {
    state.user = value;
  },
  setContact(state, value) {
    state.contact = value;
  },
};
