export default {
  flashMessages: {
    success: [],
    error: [],
    warning: [],
    info: [],
  },

  ...window.SportFinder.store,
};
