import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    contacts: [],
    total: 0,
    isLoading: true,
    // Form violations
    violations: null,
    error: null,
    cellId: null,
    datetime: new Date(),
    availabilities: {},
    overallAvailabilities: [],
    overallAvailabilitiesFrom: null,
    overallAvailabilitiesTo: null,
    choice: null,
    // New
    bookingType: null,
    product: null,
    productId: null,
    sports: [],
    selectedSportId: null,
    resources: [], // Resources, filtered by sport and context
    selectedResources: [], // Resources displayed (from user filters)
    selectedTimeSlot: null, //
    selectedAvailability: null,
    productConfiguration: null,
  },
  actions,
  getters,
  mutations,
};
