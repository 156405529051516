import {
  loadPurchaseConfiguration,
  getPurchase,
  getPurchasePaymentMethods,
} from '../../services/api/Purchase';
import {
  configure,
  edit,
  inviteToPurchase,
  cancelInvitation,
  cancelParticipation,
  approveParticipationRequest,
  denyParticipationRequest,
  cancelReplacement,
  cancelBooking,
  payWithPrepaidCard,
  payWithWallet,
  payOnSite,
  initiateOnlinePayment,
  refuse, cancelRequest,
} from '../../services/api/FieldRentalPurchase';
import { edit as editAttribution } from '../../services/api/Attribution';
import optionApi from '../../services/api/Option';
import productApi from '../../services/api/Product';
import apiRoutes from '../../router/api';

export default {
  async loadPurchase(context, id) {
    context.commit('setPurchase', await getPurchase(id));
  },

  async configurePurchase(context, { purchaseId, ...changes }) {
    context.commit('setIsLoading', true);
    try {
      const editResponse = await configure(purchaseId, changes);
      context.commit('updatePurchase', editResponse);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }
    context.commit('setIsLoading', false);
  },

  async editPurchase(context, { purchaseId, ...changes }) {
    context.commit('setIsLoading', true);
    try {
      const editResponse = await edit(purchaseId, changes);
      context.commit('updatePurchase', editResponse);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }
    context.commit('setIsLoading', false);
  },

  async invite(context, { purchaseId, selectedMembers, newContacts, locale }) {
    // console.dir(selectedMembers);
    context.commit('setIsLoading', true);
    try {
      const contacts = selectedMembers.map((s) => (s.id ? apiRoutes.contacts.get(s.id) : s));
      const attributions = newContacts;
      const inviteResponse = await inviteToPurchase(purchaseId, contacts, attributions, locale);
      context.commit('setPurchase', inviteResponse);
      context.commit('setIsLoading', false);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }
  },

  async unInvite(context, { purchaseId, attributionId }) {
    context.commit('setIsLoading', true);
    try {
      const inviteResponse = await cancelInvitation(purchaseId, attributionId);
      context.commit('setPurchase', inviteResponse);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }
    context.commit('setIsLoading', false);
  },

  async loadProduct(context, id) {
    context.commit('setProduct', await productApi.loadProduct(id));
  },

  async loadPurchaseConfiguration(context, { id, options }) {
    context.commit('setConfiguration', await loadPurchaseConfiguration(id, options));
  },

  async loadOptions(context, { sport, products, resources }) {
    context.commit('setOptions', await optionApi.getOptions(sport, products, resources));
  },

  async cancelFor(context, { purchaseId, attributionId = null }) {
    context.commit('setIsLoading', true);

    if (!attributionId) {
      const myContactId = context.rootState.user.user.contact.id;
      const attribution = context.state.purchase.attributions.find(
        (a) => a.contact && a.contact.id === myContactId
      );

      if (!myContactId) {
        throw new Error('Your contact id has not been found');
      }

      if (!attribution) {
        throw new Error('Attribution not found');
      }
      attributionId = attribution.id;
    }

    try {
      const response = await cancelParticipation(purchaseId, attributionId);
      context.commit('setPurchase', response);
      context.commit('setIsLoading', false);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }
    context.commit('setIsLoading', false);
  },

  async askForSubstitution(context, purchaseId) {
    context.commit('setIsLoading', true);

    const myContactId = context.rootState.user.user.contact.id;
    const attribution = context.state.purchase.attributions.find(
      (a) => a.contact && a.contact.id === myContactId
    );

    if (!myContactId) {
      throw new Error('Your contact id has not been found');
    }

    if (!attribution) {
      throw new Error('Attribution not found');
    }

    try {
      const response = await cancelParticipation(purchaseId, attribution.id);
      context.commit('setPurchase', response);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }
    context.commit('setIsLoading', false);
  },

  async cancelBooking(context, purchaseId) {
    context.commit('setIsLoading', true);

    try {
      const response = await cancelBooking(purchaseId);
      context.commit('setPurchase', response);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }
    context.commit('setIsLoading', false);
  },

  async cancelReplacement(context, { purchaseId, attributionId }) {
    context.commit('setIsLoading', true);

    try {
      const response = await cancelReplacement(purchaseId, attributionId);
      // console.dir(response);
      context.commit('updatePurchase', response);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }
    context.commit('setIsLoading', false);
  },

  async cancelRequest(context, { purchaseId, attributionId }) {
    context.commit('setIsLoading', true);

    try {
      const response = await cancelRequest(purchaseId, attributionId);
      // console.dir(response);
      context.commit('updatePurchase', response);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }
    context.commit('setIsLoading', false);
  },

  async refuse(context, { purchaseId, attributionId }) {
    context.commit('setIsLoading', true);

    try {
      const response = await refuse(purchaseId, attributionId);
      // console.dir(response);
      context.commit('updatePurchase', response);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }
    context.commit('setIsLoading', false);
  },

  async declineParticipationRequest(context, { purchaseId, attributionId }) {
    context.commit('setIsLoading', true);

    try {
      const response = await denyParticipationRequest(purchaseId, attributionId);
      // console.dir(response);
      context.commit('setPurchase', response);
      context.commit('setIsLoading', false);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }

    context.commit('setIsLoading', false);
  },
  async approveParticipationRequest(context, { purchaseId, attributionId }) {
    context.commit('setIsLoading', true);

    try {
      const response = await approveParticipationRequest(purchaseId, attributionId);
      // console.dir(response);
      context.commit('setPurchase', response);
      context.commit('setIsLoading', false);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }

    context.commit('setIsLoading', false);
  },

  async loadPaymentMethods(context, { purchaseId }) {
    context.commit('setIsLoading', true);
    const pm = await getPurchasePaymentMethods(purchaseId);
    context.commit('setPaymentMethods', pm);
    context.commit('setIsLoading', false);
  },

  async payWithPrepaidCard(
    context,
    { purchaseId, attributions, replace = null, creditPurchaseId, quantity, guestCount }
  ) {
    context.commit('setIsLoading', true);

    try {
      const response = await payWithPrepaidCard(
        purchaseId,
        attributions,
        creditPurchaseId,
        replace,
        quantity,
        guestCount
      );
      // console.dir(response);
      context.commit('setPurchase', response);
      context.commit('setIsLoading', false);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }

    context.commit('setIsLoading', false);
  },

  async payWithWallet(
    context,
    { purchaseId, attributions, replace = null, walletId, quantity, guestCount }
  ) {
    context.commit('setIsLoading', true);
    try {
      const response = await payWithWallet(
        purchaseId,
        attributions,
        walletId,
        replace,
        quantity,
        guestCount
      );
      // console.dir(response);
      context.commit('setPurchase', response);
      context.commit('setIsLoading', false);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }

    context.commit('setIsLoading', false);
  },

  async payOnSite(context, { purchaseId, attributions, guestCount, replace = null }) {
    context.commit('setIsLoading', true);

    try {
      const response = await payOnSite(purchaseId, attributions, replace, guestCount);
      // console.dir(response);
      context.commit('setPurchase', response);
      context.commit('setIsLoading', false);
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }

    context.commit('setIsLoading', false);
  },

  async storePaymentIntentConfig(context, config) {
    context.commit('setPaymentConfig', config);
  },

  async createPaymentIntent(context, { purchaseType = 'fieldrental', purchaseId, body }) {
    context.commit('setIsLoading', true);
    const payload = body;
    payload.attributions = payload.attributions.map((a) => ({ id: a.id }));

    try {
      const response = await initiateOnlinePayment(purchaseType, purchaseId, payload);
      context.commit('setPaymentIntent', response);
      context.commit('setIsLoading', false);

      return response;
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }
  },

  async editAttribution(context, { attributionId, ...changes }) {
    context.commit('setIsLoading', true);
    try {
      const editResponse = await editAttribution(attributionId, changes);
      let attributions = context.state.purchase.attributions;
      attributions = attributions.filter((a) => a && a?.id !== attributionId);
      context.commit('updatePurchase', {
        ...context.state.purchase,
        attributions: [...attributions, editResponse].sort((a, b) => a.id - b.id),
      });
    } catch (e) {
      context.commit('setIsLoading', false);
      throw e;
    }
    context.commit('setIsLoading', false);
  },
};
