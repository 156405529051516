import routes from '../../router/api';
import { sendPxxxRequest, sendGetRequest, objectToQueryString } from './Hydra';

export async function edit(attributionId, body, locale = 'fr', XSfUserId = null) {
  const uri = new URL(routes.attribution.put(attributionId), window.location.origin);

  const response = await sendPxxxRequest(uri, body, 'PUT', {
    Accept: 'application/ld+json',
    'Accept-Language': locale,
    ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
  });

  return response;
}

export async function getAttributions(options = {}, XSfUserId = null) {
  const uri = new URL(routes.attribution.get, window.location.origin);

  if (!Object.hasOwnProperty.call(options, 'page') || !options.page) {
    // eslint-disable-next-line no-param-reassign
    options.page = 1;
  }

  uri.search = objectToQueryString(options);
  return sendGetRequest(uri, {
    ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
  });
}
