import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    displayMode: 'list',
    isLoading: false,
    isMapLoading: false,

    // Data
    mapResults: [],
    results: [],

    // Pagination
    paginator: {
      count: 0,
      page: 1,
      page_count: 0,
      limit: 15,
    },

    // Data from the geocoder
    geocoding: null,

    // Data from the map
    bounds: null,

    // Default values overwritten by SportFinder.store
    activityTypes: {}, // filters
    parameters: {}, // filters
    sport: null,
    sports: {},

    predefinedSearch: false,

    when: null,

    zone: null,
    zoneName: '',

    // Override
    ...window.SportFinder.store.map,
  },
  actions,
  getters,
  mutations,
};
