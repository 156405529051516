import { actions, mutations } from './constants';
import scannerApi from '../../services/api/Scanner';
import ScanResultTypes from '../../types/scanner/ScanResultType';

// const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export default {
  [actions.FETCH_REFERENCE]: async (context, reference) => {
    try {
      context.commit(mutations.SET_IS_LOADING, true);

      // await sleep(2000);

      /** @type {ScanResult} */
      const response = await scannerApi.fetchReference(reference);

      // console.dir(response);
      // Ensure response correctness

      context.commit(mutations.SET_SCAN_RESULT, response);
      context.commit(mutations.SET_IS_ACCESS_CODE_NOT_FOUND, response.result === 'NOT_FOUND');
    } catch (e) {
      if (e.status && e.status === 404) {
        context.commit(mutations.SET_IS_ACCESS_CODE_NOT_FOUND, true);
      } else {
        context.commit(mutations.SET_ERROR, e.name);
      }
    }
    context.commit(mutations.SET_IS_LOADING, false);
  },
  [actions.FETCH_SCAN_HISTORY]: async (context, { reference = null, page = null }) => {
    try {
      context.commit(mutations.SET_IS_LOADING, true);
      const response = await scannerApi.fetchScanHistory(reference, page);
      // We gonna show only the accesses
      const members = response['hydra:member'];

      // If page is set, then we want to concat
      context.commit(page ? mutations.ADD_SCAN_HISTORY : mutations.SET_SCAN_HISTORY, members);
    } catch (e) {
      context.commit(mutations.SET_ERROR, e.name);
    }
    context.commit(mutations.SET_IS_LOADING, false);
  },
  [actions.FETCH_ACCESS_HISTORY]: async (context, { reference = null, page = null }) => {
    try {
      context.commit(mutations.SET_IS_LOADING, true);
      const response = await scannerApi.fetchScanHistory(reference, page);
      // We gonna show only the accesses
      const members = response['hydra:member'].filter(
        (m) => m.result === ScanResultTypes.ACCESS_GRANTED
      );

      context.commit(page ? mutations.ADD_ACCESS_HISTORY : mutations.SET_ACCESS_HISTORY, members);
    } catch (e) {
      context.commit(mutations.SET_ERROR, e.name);
    }
    context.commit(mutations.SET_IS_LOADING, false);
  },
};
