/* eslint-disable no-param-reassign */
import { updateField } from 'vuex-map-fields';
import { mutations } from './constants';

export default {
  updateField,
  [mutations.SET_IS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  [mutations.SET_OPENING_HOURS]: (state, value) => {
    state.choice = value;
  },
  [mutations.SET_RESOURCES]: (state, value) => {
    state.resources = value;
  },
  [mutations.SET_ID]: (state, value) => {
    state.id = value;
  },
  [mutations.SET_ADDRESS]: (state, value) => {
    state.address = value;
  },
};
