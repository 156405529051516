export const getters = {
  IS_BUSY: 'IS_BUSY',
  SUBSCRIPTION_TYPES: 'SUBSCRIPTION_TYPES',
  SEARCH_TERM: 'SEARCH_TERM',
  SORT_BY: 'SORT_BY',
  URL_SEARCH_PARAMETERS: 'URL_SEARCH_PARAMETERS',
  SORT_ORDER: 'SORT_ORDER',
  PAGE: 'PAGE',
  PER_PAGE: 'PER_PAGE',
  SELECTED_SUBSCRIPTIONS: 'SELECTED_SUBSCRIPTIONS',
  CONTACT_TYPE: 'CONTACT_TYPE',
};

export const actions = {
  LOAD_SUBSCRIPTION_TYPES: 'LOAD_SUBSCRIPTION_TYPES',
};

export const mutations = {
  SET_IS_BUSY: 'SET_IS_BUSY',
  SET_SUBSCRIPTION_TYPES: 'SET_SUBSCRIPTION_TYPES',
  SET_SELECTED_SUBSCRIPTIONS: 'SET_SELECTED_SUBSCRIPTIONS',
  SET_CONTACT_TYPE: 'SET_CONTACT_TYPE',
  SET_SORT_BY: 'SET_SORT_BY',
  SET_SORT_ORDER: 'SET_SORT_ORDER',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  SET_PAGE: 'SET_PAGE',
  SET_LIMIT: 'SET_LIMIT',
};
