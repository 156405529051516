import * as types from './mutation_types';

export default {
  [types.CONTACT_SELECT_SET_SELECTED](state, items) {
    Object.assign(state, { items });
  },
  [types.CONTACT_SELECT_ADD_SELECTED](state, item) {
    const { items } = state;
    items.push(item.toString());
    Object.assign(state, { items });
  },
};
