import { searchCenters, searchCentersForMap } from '../../services/api/Center';

export default {
  async handleSportChange(context, sport) {
    context.commit('setIsLoading', true);
    context.commit('setSport', sport);
  },
  async loadCenters(context, append = false) {
    const options = context.getters.loadCentersApiOptions;

    try {
      context.commit('setIsLoading', true);
      const response = await searchCenters(options);
      context.commit(
        'updateResults',
        append ? context.state.results.concat(response['hydra:member']) : response['hydra:member']
      );
      context.commit('updatePaginationCount', response['hydra:totalItems']);
      context.commit(
        'updatePaginationPageCount',
        Math.ceil(response['hydra:totalItems'] / response['hydra:member'].length)
      );
      context.commit('setIsLoading', false);
      return response;
    } catch (e) {
      console.error(e);
    }

    context.commit('setIsLoading', false);
    return undefined;
  },
  async loadMore(context) {
    context.commit('updatePaginationPage', context.state.paginator.page + 1);
    await context.dispatch('loadCenters', true);
  },
  async loadCentersForMap(context) {
    context.commit('setMapIsLoading', true);
    let params = {};

    const zoneIsCity = context.state.zone && !['commune', 'city'].includes(context.state.zone.type);
    if (!context.state.geocoding && !zoneIsCity) {
      params = { zone: context.state.zone.id };
    }

    if (context.state.bounds) {
      const latitude = `${context.state.bounds.to.lat}..${context.state.bounds.from.lat}`;
      const longitude = `${context.state.bounds.from.lng}..${context.state.bounds.to.lng}`;
      params = {
        'address.latitude[between]': latitude,
        'address.longitude[between]': longitude,
      };
    }
    const data = await searchCentersForMap({
      ...params,
      ...(context.state.sport ? { sports: [context.state.sport] } : {}),
      ...(context.state.filters && Object.keys(context.state.filters).length
        ? { filters: context.state.filters }
        : {}),
    });

    context.commit('setMapResults', data['hydra:member'] ?? []);
    context.commit('setMapIsLoading', false);
  },
  // On geocoding
  async prepareNavigation(context) {
    context.commit('reset');
  },
};
