import { sendPostRequest, sendGetRequest } from './Hydra';

export default {
  /**
   * @param reference
   * @return {Promise<ScanResult>}
   */
  async fetchReference(reference) {
    // return sendGetRequest(`/api/qrcodes/${reference}`);
    // noinspection UnnecessaryLocalVariableJS
    const response = await sendPostRequest('/api/qrcodes/scan', {
      code: reference,
    });

    // console.debug(response);
    return response;
  },
  sendCustomApprovalStatus(reference, status) {
    return sendPostRequest('/api/qrcodes/scan', {
      result: status,
      access_code: {
        code: reference,
      },
    });
  },
  fetchScanHistory(reference = null, page = null) {
    return sendGetRequest(
      reference ? `/api/access_logs/${reference}` : `/api/access_logs${page ? `?page=${page}` : ''}`
    );
  },
};
