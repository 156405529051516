import apiRoutes from '../../router/api';
import { objectToQueryString, sendGetRequest } from './Hydra';

export default {
  async getOptions(sport, products = [], resources = []) {
    // Test required values
    if (!sport) {
      throw new Error('Sport id is required');
    }

    const uri = new URL(apiRoutes.options.get(), window.location.origin);
    const selectedOptions = {};
    if (products) {
      selectedOptions.optionAssociatedProducts = products;
    }
    if (resources) {
      selectedOptions.resources = resources;
    }
    if (sport) {
      selectedOptions.sport = sport;
    }
    uri.search = objectToQueryString(selectedOptions);

    const response = await sendGetRequest(uri);

    return response['hydra:member'];
  },
  getOption(id) {
    // Test required values
    if (!id) {
      throw new Error('option id required');
    }

    const uri = new URL(apiRoutes.options.get(id), window.location.origin);
    return sendGetRequest(uri);
  },
};
