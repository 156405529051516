import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { SORT_ASC } from './SortOrders';

export default {
  namespaced: true,
  state: {
    page: 1,
    // @todo: use constant ? This have to match Api return
    limit: 10,
    subscriptions: [],
    selectedSubscriptions: [],
    contactType: null,
    // @todo: remove hardcoded value ?
    sortBy: 'first_name',
    sortOrder: SORT_ASC,
    searchTerm: '',
    isBusy: false,
  },
  actions,
  getters,
  mutations,
};
