export const getters = {
  CONTACTS: 'CONTACTS',
  IS_BUSY: 'IS_BUSY',
  ERROR: 'ERROR',
  TOTAL: 'TOTAL',
};

export const actions = {
  LOAD_CONTACTS: 'LOAD_CONTACTS',
};

export const mutations = {
  SET_CONTACTS: 'SET_CONTACTS',
  SET_IS_BUSY: 'SET_IS_BUSY',
  SET_ERROR: 'SET_ERROR',
  SET_TOTAL: 'SET_TOTAL',
};
