export default class TooManyRequestsError extends Error {
  constructor(errors) {
    const m = 'Request frequency too high';
    super(m);
    this.message = m;
    this.errors = errors;
    // This seems to be implemented in swagger or graphQl, used in original code (api-platform)
    // Error.captureStackTrace(this, this.constructor);
    this.name = this.constructor.name;
  }
}
