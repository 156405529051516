export default class SubmissionError extends Error {
  constructor(errors, status = 200) {
    super(`SubmissionError (${status})`);
    this.status = status;
    this.errors = errors;
    // This seems to be implemented in swagger or graphQl, used in original code (api-platform)
    // Error.captureStackTrace(this, this.constructor);
    this.name = this.name || this.constructor.name;
  }
}
