import apiRoutes from '../../router/api';
import { objectToQueryString, sendGetRequest } from './Hydra';

export async function loadCenter(id) {
  if (!id) {
    throw new Error('Product id required');
  }

  const uri = new URL(apiRoutes.center.get(id), window.location.origin);
  return sendGetRequest(uri);
}

export async function searchCenters(options) {
  // const selectedOptions = {};
  // Object.keys(options).filter((k) => options[k]).forEach((k) => {
  //   selectedOptions[k] = options[k];
  // });

  const uri = new URL(apiRoutes.center.search, window.location.origin);
  uri.search = objectToQueryString(options, '');

  return sendGetRequest(uri);
}

export async function searchCentersForMap(options) {
  const selectedOptions = {};
  Object.keys(options)
    .filter((k) => options[k])
    .forEach((k) => {
      selectedOptions[k] = options[k];
    });

  const uri = new URL(apiRoutes.center.searchMap, window.location.origin);
  uri.search = objectToQueryString(options, '');

  return sendGetRequest(uri);
}
