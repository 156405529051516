export default class CalendarEvent {
  /** @var {string|Date} */
  start; // '2018-11-19 12:00', // Required.

  /** @var {string|Date} */
  end; // '2018-11-19 14:00', // Required.

  // Instead of formatted dates, you can also provide Javascript Date objects:
  // start: new Date(2018, 11 - 1, 19, 12, 0),
  // end: new Date(2018, 11 - 1, 19, 14, 0),
  /** @var {string|undefined} */
  title; // Optional.

  /** @var {string|undefined} */
  content; // Optional.

  /** @var {string|undefined} */
  // contentFull; // opens in a dialog
  /** @var {string|undefined} */
  class; // Optional - space-separated css classes.

  /** @var {boolean|undefined} */
  background; // Optional. (Event type not CSS property)

  /** @var {number|string|undefined} */
  split; // Optional.
  /** @var {boolean|undefined} */
  // allDay; // Optional.
  /** @var {boolean|undefined} */
  // deletable; // optional - force undeletable when events are editable.
  /** @var {boolean|undefined} */
  // resizable; // optional - force unresizable when events are editable.
  /** @var {number|undefined} */
  // startTimeMinutes;
  /** @var {number|undefined} */
  // endTimeMinutes;

  constructor(...args) {
    // console.info(args);
    Object.keys(args[0]).forEach((arg) => {
      if (Object.hasOwnProperty.call(this, arg)) {
        // console.log('bing');
        this[arg] = args[0][arg];
      }
    });

    // console.log(this);
  }

  computedCssClass() {
    return this.class;
  }

  toVueCalEvent() {
    return {
      ...this,
      class: this.computedCssClass(),
    };
    // return new Proxy(this)
    // const obj = {};
    // for (const prop of Object.keys(CalendarEvent)) {
    //   console.info(prop, Object.hasOwnProperty.call(CalendarEvent, prop), this[prop])
    //   if (Object.hasOwnProperty.call(this, prop) && this[prop]) {
    //     obj[prop] = this[prop];
    //   }
    // }
    // return obj;
  }

  // eslint-disable-next-line class-methods-use-this
  onEventCreate() {
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  onEventDragCreate() {
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  onEventDragClick() {
    return undefined;
  }
}
