import { getField } from 'vuex-map-fields';

export default {
  getField,
  loadCentersApiOptions(state, getters) {
    // console.dir(state.zone.type)
    return {
      // Pagination
      page: state.paginator.page,
      limit: 15,

      // Discrimination
      ...(state.geocoding || (state.zone && ['city', 'commune'].includes(state.zone.type))
        ? { geocenter: getters.geocenter }
        : {}),
      // geocenter: getters.geocenter,
      ...(state.sport ? { sports: [state.sport] } : {}),
      ...(state.zone && !state.geocoding ? { zone: state.zone.id } : {}),
    };
  },

  geocenter(state) {
    // console.dir(state.geocoding);
    if (state.geocoding) {
      return [state.geocoding.lat, state.geocoding.lng];
    }

    if (state.zone && state.zone.type !== 'country' && state.zone.latitude) {
      return [state.zone.latitude, state.zone.longitude];
    }

    return [50, 4];
  },
  queryString(state) {
    const query = {};
    let search = false;
    let q = '';

    if (state.sport !== null) {
      search = true;
      query.what = state.sport;
    }

    if (state.where !== null && state.geocoding) {
      search = true;
      query.where = {
        lat: state.geocoding.lat,
        lng: state.geocoding.lng,
        name: state.zoneName,
      };
    }

    if (state.when !== null) {
      search = true;
      query.when = state.when;
    }

    if (search) {
      q = `?q=${encodeURIComponent(JSON.stringify(query))}`;
    }

    return q;
  },
};
