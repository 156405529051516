/* eslint-disable no-param-reassign */
import { mutations } from './constants';

export default {
  [mutations.SET_IS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  [mutations.SET_SCAN_HISTORY]: (state, scanHistory) => {
    state.scanHistory = scanHistory;
  },
  [mutations.ADD_SCAN_HISTORY]: (state, scanHistory) => {
    state.scanHistory = state.scanHistory.concat(scanHistory);
  },
  [mutations.SET_ACCESS_HISTORY]: (state, accessHistory) => {
    state.accessHistory = accessHistory;
  },
  [mutations.ADD_ACCESS_HISTORY]: (state, accessHistory) => {
    state.accessHistory = state.accessHistory.concat(accessHistory);
  },
  [mutations.SET_IS_ACCESS_CODE_NOT_FOUND]: (state, isBookingNotFound) => {
    state.isBookingNotFound = isBookingNotFound;
  },
  [mutations.SET_SCAN_RESULT]: (state, scanResult) => {
    state.scanResult = scanResult;
  },
  [mutations.SET_VIOLATIONS]: (state, violations) => {
    state.violations = violations;
  },
  [mutations.SET_ERROR]: (state, error) => {
    state.error = error;
  },
};
