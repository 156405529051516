import Vue from 'vue';

Vue.mixin({
  computed: {
    spflocale() {
      return window.SportFinder.store.locale;
    },
    intlCurrencyFormatter() {
      return new Intl.NumberFormat(this.spflocale, { style: 'currency', currency: 'EUR' });
    },
  },
  methods: {
    trans(...args) {
      return Translator.trans(...args);
    },
    transChoice(...args) {
      return Translator.transChoice(...args);
    },
  },
});
