import { actions, mutations } from './constants';
import SubscriptionApi from '../../../services/api/Subscription';

export default {
  async [actions.LOAD_SUBSCRIPTION_TYPES]({ commit }, partnerId) {
    commit(mutations.SET_IS_BUSY, true);

    const filters = await SubscriptionApi.loadSubscriptionTypesByPartner(partnerId);

    commit(mutations.SET_SUBSCRIPTION_TYPES, filters);
    commit(mutations.SET_IS_BUSY, false);
  },
};
