/* eslint-disable no-param-reassign */
import { updateField } from 'vuex-map-fields';
import { mutations } from './constants';
import { SORT_ORDER } from './SortOrders';

export default {
  updateField,

  [mutations.SET_IS_BUSY](state, value) {
    state.isBusy = value;
  },
  [mutations.SET_SUBSCRIPTION_TYPES](state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  [mutations.SET_SELECTED_SUBSCRIPTIONS](state, subscriptions) {
    state.selectedSubscriptions = subscriptions;
  },
  [mutations.SET_CONTACT_TYPE](state, value) {
    state.contactType = value;
  },
  [mutations.SET_SEARCH_TERM](state, searchTerm) {
    state.searchTerm = searchTerm;
  },
  [mutations.SET_SORT_BY](state, sortBY) {
    state.sortBY = sortBY;
  },
  [mutations.SET_SORT_ORDER](state, sortOrder) {
    if (!Object.values(SORT_ORDER).includes(sortOrder)) {
      throw new Error('Sort order value not understood');
    }
    state.sortOrder = sortOrder;
  },
  [mutations.SET_PAGE](state, page) {
    state.page = page;
  },
  [mutations.SET_LIMIT](state, limit) {
    state.limit = limit;
  },
};
