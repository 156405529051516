import { objectToQueryString, sendGetRequest } from './Hydra';
import apiRoutes from '../../router/api';

export function formatFiltersData(options) {
  // console.debug('formatFiltersData', options, Object.keys(options));

  const selectedOptions = {};
  Object.keys(options)
    .filter((k) => options[k] !== null)
    .forEach((k) => {
      selectedOptions[k] = options[k];
    });

  // Test required values
  // if (!selectedOptions.partner) {
  //   throw new Error('PartnerId required');
  // }

  // console.debug(objectToQueryString(selectedOptions));
  return objectToQueryString(selectedOptions);
}

export async function getMyPurchases(options) {
  const uri = new URL('/api/purchases/mypurchases', window.location.origin);

  if (!Object.hasOwnProperty.call(options, 'page') || !options.page) {
    // eslint-disable-next-line no-param-reassign
    options.page = 1;
  }

  uri.search = formatFiltersData(options);
  return sendGetRequest(uri);
}

export async function getPurchases(options) {
  const uri = new URL('/api/purchases', window.location.origin);

  if (!Object.hasOwnProperty.call(options, 'page') || !options.page) {
    // eslint-disable-next-line no-param-reassign
    options.page = 1;
  }

  uri.search = formatFiltersData(options);
  return sendGetRequest(uri);
}

export async function getPurchase(id, locale = 'fr', XSfUserId = null) {
  const params = [['locale', locale]];
  if (XSfUserId) {
    params.push(['X-SF-USER-ID', XSfUserId]);
  }
  const uri = new URL(apiRoutes.purchases.get(id), window.location.origin);
  uri.search = new URLSearchParams(params).toString();

  const response = await sendGetRequest(uri);

  return response;
}

export async function loadPurchaseConfiguration(purchaseId, options = {}) {
  // Test required values
  if (!purchaseId) {
    throw new Error('purchaseId required');
  }

  const selectedOptions = {};
  Object.keys(options)
    .filter((k) => options[k])
    .forEach((k) => {
      selectedOptions[k] = options[k];
    });

  const uri = new URL(apiRoutes.purchases.configuration(purchaseId), window.location.origin);
  uri.search = objectToQueryString(selectedOptions, '');
  return sendGetRequest(uri, { Accept: 'application/json' });
}

export async function getPurchasePaymentMethods(purchaseId) {
  const uri = new URL(apiRoutes.purchases.paymentMethods(purchaseId), window.location.origin);
  return sendGetRequest(uri, { Accept: 'application/json' });
}
