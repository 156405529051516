import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    contacts: [],
    total: 0,
    isBusy: false,
    // @todo: use me ?
    error: null,
  },
  actions,
  getters,
  mutations,
};
