/* eslint-disable no-param-reassign */
import { updateField } from 'vuex-map-fields';
import { mutations } from './constants';

export default {
  updateField,
  [mutations.ADD_BLANK_CONTACT]: (state) => {
    state.contacts.push({
      id: null,
      firstName: '',
      lastName: '',
      birthdate: '',
    });
  },
  [mutations.REMOVE_CONTACT]: (state, contact) => {
    state.contacts.splice(state.contacts.indexOf(contact));
  },
  [mutations.REMOVE_CONTACT_AT_INDEX]: (state, index) => {
    state.contacts.splice(index, 1);
  },
  [mutations.SET_CONTACTS]: (state, contacts) => {
    state.contacts = contacts;
  },
  [mutations.SET_TOTAL]: (state, total) => {
    state.total = total;
  },
  [mutations.SET_IS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  [mutations.SET_VIOLATIONS]: (state, violations) => {
    state.violations = violations;
  },
  [mutations.SET_ERROR]: (state, error) => {
    state.error = error;
  },
  [mutations.SET_DATETIME]: (state, value) => {
    state.datetime = value;
  },
  [mutations.SET_CELL_ID]: (state, value) => {
    state.cellId = value;
  },
  [mutations.SET_OVERALL_AVAILABILITIES]: (state, value) => {
    state.overallAvailabilities = value;
  },
  [mutations.SET_OVERALL_AVAILABILITIES_FETCHED_RANGE_FROM]: (state, value) => {
    state.overallAvailabilitiesFrom = value;
  },
  [mutations.SET_OVERALL_AVAILABILITIES_FETCHED_RANGE_TO]: (state, value) => {
    state.overallAvailabilitiesTo = value;
  },
  [mutations.SET_CHOICE]: (state, value) => {
    state.choice = value;
  },
  [mutations.SET_RESOURCES]: (state, resources) => {
    state.resources = resources;
  },
  [mutations.SET_SHOWN_RESOURCES]: (state, resources) => {
    state.selectedResources = resources;
  },
  [mutations.SET_PRODUCT]: (state, product) => {
    state.product = product;
  },
  [mutations.SET_SPORT]: (state, sport) => {
    if (!state.sports.find((s) => s.id === sport.id)) {
      state.sports.push(sport);
    }
  },
  [mutations.SET_SELECTED_SPORT_ID]: (state, sport) => {
    state.selectedSportId = sport;
  },
  [mutations.SET_PRODUCT_CONFIGURATION]: (state, config) => {
    state.productConfiguration = config;
  },
};
