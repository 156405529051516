import { getField } from 'vuex-map-fields';
import { getters } from './constants';
import FieldAvailabilityEvent from '../../components/common/calendar/CalendarEvents/FieldAvailabilityEvent';

export default {
  getField,
  [getters.CONTACTS]: (state) => state.contacts,
  [getters.TOTAL]: (state) => state.total,
  [getters.IS_LOADING]: (state) => state.isLoading,
  [getters.VIOLATIONS]: (state) => state.violations,
  [getters.ERROR]: (state) => state.error,
  [getters.DATETIME]: (state) => state.datetime,
  [getters.CELL_ID]: (state) => state.cellId,
  [getters.CHOICE]: (state) => state.choice,

  [getters.AVAILABILITIES]: (state) =>
    Object.values(state.availabilities)
      .reduce((prev, curr) => (Array.isArray(curr) ? prev.concat(curr) : prev), [])
      .map(
        (a) =>
          new FieldAvailabilityEvent({
            availability: 'available',
            start: `${a.start.format('YYYY-MM-DD')} ${a.start.format('HH:MM')}`,
            end: `${a.end.format('YYYY-MM-DD')} ${a.end.format('HH:MM')}`,
            color: 'green',
            amount: a.price,
            resource: a.resource.id,
            fieldRentalId: 1000,
          })
      ),

  // [getters.OVERALL_AVAILABILITIES]: (state) => {
  //   const processedSlots = [];
  //   return Object.values(state.availabilities)
  //     .reduce((prev, curr) => (Array.isArray(curr) ? prev.concat(
  //       curr.filter((a) => {
  //         if (!processedSlots.includes(a.start.valueOf())) {
  //           processedSlots.push(a.start.valueOf());
  //           return true;
  //         }
  //         return false;
  //       }),
  //     ) : prev), [])
  //     .map((a) => new FieldAvailabilityEvent({
  //       availability: 'available',
  //       start: `${a.start.format('YYYY-MM-DD')} ${a.start.format('HH:mm')}`,
  //       end: `${a.end.format('YYYY-MM-DD')} ${a.end.format('HH:mm')}`,
  //       color: 'green',
  //       amount: a.price,
  //       fieldRentalId: 1000,
  //     }));
  // },
};
