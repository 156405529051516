import Vue from 'vue';
import Vuex from 'vuex';
import Getters from './getters';
import Mutations from './mutations';
import State from './state';
import booking from './booking';
import calendar from './calendar';
import center from './center';
import contact from './contact';
import infobox from './infobox';
import map from './map';
import match from './match';
import member from './member';
import scanner from './scanner';
import user from './user';

Vue.use(Vuex);

export default new Vuex.Store({
  state: State,
  getters: Getters,
  mutations: Mutations,
  modules: {
    booking,
    calendar,
    center,
    contact,
    map,
    match,
    member,
    infobox,
    scanner,
    user,
  },
});
