export const actions = {
  FETCH_TOTAL: 'FETCH_TOTAL',
  FETCH_AVAILABILITIES: 'FETCH_AVAILABILITIES',
  LOAD_RESOURCES: 'LOAD_RESOURCES',
  LOAD: 'LOAD',
  LOAD_PRODUCT: 'LOAD_PRODUCT',
  LOAD_AVAILABILITIES: 'LOAD_AVAILABILITIES',
  LOAD_SPORT: 'LOAD_SPORT',
  CLEAR_AVAILABILITIES: 'CLEAR_AVAILABILITIES',
  CLEAR_CALENDAR: 'CLEAR_CALENDAR',
  GET_EXPIRED_AVAILABILITIES: 'GET_EXPIRED_AVAILABILITIES',
  SET_AVAILABILITIES: 'SET_AVAILABILITIES',
  LOAD_OVERALL_AVAILABILITIES: 'LOAD_OVERALL_AVAILABILITIES',
  SET_OVERALL_AVAILABILITIES: 'SET_OVERALL_AVAILABILITIES',
  CLEAN_AVAILABILITIES: 'CLEAN_AVAILABILITIES',
  LOAD_PRODUCT_CONFIGURATION: 'LOAD_PRODUCT_CONFIGURATION',
};

export const getters = {
  CONTACTS: 'CONTACTS',
  TOTAL: 'TOTAL',
  TOTAL_LOADING: 'TOTAL_LOADING',
  IS_LOADING: 'IS_LOADING',
  VIOLATIONS: 'VIOLATIONS',
  ERROR: 'ERROR',
  DATETIME: 'DATETIME',
  CELL_ID: 'CELL_ID',
  AVAILABILITIES: 'AVAILABILITIES',
  CHOICE: 'CHOICE',
  OVERALL_AVAILABILITIES: 'OVERALL_AVAILABILITIES',
};

export const mutations = {
  ADD_BLANK_CONTACT: 'ADD_BLANK_CONTACT',
  REMOVE_CONTACT: 'REMOVE_CONTACT',
  REMOVE_CONTACT_AT_INDEX: 'REMOVE_CONTACT_AT_INDEX',
  SET_CONTACTS: 'SET_CONTACTS',
  SET_TOTAL: 'SET_TOTAL',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_VIOLATIONS: 'SET_VIOLATIONS',
  SET_ERROR: 'SET_ERROR',
  SET_DATETIME: 'SET_DATETIME',
  SET_CELL_ID: 'SET_CELL_ID',
  SET_AVAILABILITIES: 'SET_AVAILABILITIES',
  SET_CHOICE: 'SET_CHOICE',
  SET_RESOURCES: 'SET_RESOURCES',
  SET_SHOWN_RESOURCES: 'SET_SHOWN_RESOURCES',
  SET_PRODUCT: 'SET_PRODUCT',
  SET_SELECTED_SPORT_ID: 'SET_SELECTED_SPORT_ID',
  SET_SPORT: 'SET_SPORT',
  SET_OVERALL_AVAILABILITIES: 'SET_OVERALL_AVAILABILITIES',
  SET_OVERALL_AVAILABILITIES_FETCHED_RANGE_FROM: 'SET_OVERALL_AVAILABILITIES_FETCHED_RANGE_FROM',
  SET_OVERALL_AVAILABILITIES_FETCHED_RANGE_TO: 'SET_OVERALL_AVAILABILITIES_FETCHED_RANGE_TO',
  SET_PRODUCT_CONFIGURATION: 'SET_PRODUCT_CONFIGURATION',
};
