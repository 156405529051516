import { sendGetRequest } from './Hydra';
import apiRoutes from '../../router/api';

export default {
  /**
   * @param {string} partnerId
   * @return {Promise<ScanResult>}
   */
  async loadSubscriptionTypesByPartner(partnerId) {
    return sendGetRequest(apiRoutes.subscriptionTypesByPartner(partnerId));
  },
};
