import { sendGetRequest } from './Hydra';

function objToQueryString(obj, prefix) {
  const fields = [];
  const k = Object.keys(obj);
  if (obj && k.length) {
    k.forEach((i) => {
      if (typeof obj[i] === 'object') {
        fields.push(objToQueryString(obj[i], prefix ? `${prefix}[${i}]` : i));
      } else {
        fields.push(
          `${prefix ? `${prefix}[${i}]` : i}=${typeof obj[i] === 'undefined' ? '' : encodeURIComponent(obj[i])}`
        );
      }
    });
  } else if (prefix) {
    fields.push(`${prefix}=`);
  }
  return fields.join('&');
}

export default {
  async loadResource(options) {
    const selectedOptions = {};
    Object.keys(options)
      .filter((k) => options[k])
      .forEach((k) => {
        selectedOptions[k] = options[k];
      });

    if (!Object.hasOwnProperty.call(selectedOptions, 'page') || !selectedOptions.page) {
      selectedOptions.page = 1;
    }

    // Test required values
    if (!selectedOptions.partner) {
      throw new Error('PartnerId required');
    }

    const uri = new URL('/api/resources', window.location.origin);
    uri.search = objToQueryString(selectedOptions, '');

    return sendGetRequest(uri);
  },
};
