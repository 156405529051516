// import { ENTRYPOINT } from '../config/entrypoint';

import SubmissionError from '../../error/SubmissionError';
import NotFoundError from '../../error/NotFoundError';
import TooManyRequestsError from '../../error/TooManyRequestsError';
import UnprocessableEntityError from '../../error/UnprocessableEntityError';
import InternalServerError from '../../error/InternalServerError';

const MIME_TYPE = 'application/ld+json';

export default async function fetchHydra(id, rawOptions = {}) {
  const options = rawOptions;
  if (typeof options.headers === 'undefined') options.headers = new Headers();
  if (options.headers.get('Accept') === null) options.headers.set('Accept', MIME_TYPE);

  if (
    options.body !== 'undefined' &&
    !(options.body instanceof FormData) &&
    options.headers.get('Content-Type') === null
  )
    options.headers.set('Content-Type', MIME_TYPE);

  const response = await window.fetch(
    // new URL(id, ENTRYPOINT),
    // new URL(id),
    id,
    options
  );

  // We need to let the 403 pass because useful data
  // is returned under this status in the scanner app
  if (response.ok || response.status === 403) return response;

  const json = await response.json();
  const errorName = json['hydra:description'] || response.statusText;
  const errors = { _error: errorName };

  if (json.violations) {
    json.violations.forEach((violation) => {
      errors[violation.propertyPath] = violation;
    });
    throw new UnprocessableEntityError(response.status, errors);
  }

  // eslint-disable-next-line no-console
  console.debug('Error without violations, sending SubmissionError', json);
  throw new SubmissionError(errors, response.status);
}

export async function sendGetRequest(url, headers = {}) {
  try {
    const response = await fetchHydra(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/ld+json',
        'Accept-Language': window.SportFinder.store.locale,
        ...headers,
      }),
    });

    if (response.status === 404) {
      // noinspection ExceptionCaughtLocallyJS
      throw new NotFoundError();
    }

    if (response.status === 500) {
      // noinspection ExceptionCaughtLocallyJS
      throw new InternalServerError(response.status, []);
    }

    return response.json();
  } catch (e) {
    if (e.message === 'Too Many Requests') {
      throw new TooManyRequestsError();
    }
    throw e;
  }
}

export async function sendPxxxRequest(url, body, method = 'POST', headers = {}) {
  const contentType = method === 'PATCH' ? 'application/merge-patch+json' : 'application/json';
  const response = await fetchHydra(url, {
    method,
    headers: new Headers({
      'Content-Type': contentType,
      ...headers,
    }),
    body: JSON.stringify(body),
  });

  if (response.headers.has('content-type')) {
    return await response.json();
  }

  return null;
}

export async function sendPostRequest(url, body, headers = {}) {
  return sendPxxxRequest(url, body, undefined, headers);
}

export async function sendPatchRequest(url, body, headers = {}) {
  return sendPxxxRequest(url, body, 'PATCH', headers);
}

export function objectToQueryString(obj, prefix) {
  const fields = [];

  if (obj) {
    const k = Object.keys(obj);
    if (k.length) {
      k.forEach((i) => {
        if (typeof obj[i] === 'object' && !(obj[i] instanceof Date)) {
          fields.push(objectToQueryString(obj[i], prefix ? `${prefix}[${i}]` : i));
        } else {
          // We only want object name in brackets, not index keys
          const innerBracketKeys = Array.isArray(obj) ? '' : i;
          let formattedValue = '';

          if (typeof obj[i] === 'undefined') {
            formattedValue = '';
          } else if (obj[i] instanceof Date) {
            formattedValue = `${obj[i].getFullYear()}-${obj[i].getMonth() + 1}-${obj[i].getDate() < 10 ? '0' : ''}${obj[i].getDate()}`;
          } else {
            formattedValue = encodeURIComponent(obj[i]);
          }

          fields.push(`${prefix ? `${prefix}[${innerBracketKeys}]` : i}=${formattedValue}`);
        }
      });
    }
    return fields.filter((f) => !!f).join('&');
  }

  return undefined;
}

/**
 * @param {URLSearchParams} params
 * @param {string[]} urlParamsWhitelist
 * @param {Record<string, any>} stateData
 * @return {Record<string, any>}
 */
export function queryStringToObject(params, urlParamsWhitelist, stateData = {}) {
  const data = { ...stateData };
  params.forEach((v, k) => {
    let formattedValue = v;
    const isCompositeKey = k.includes('[');
    const isArrayKey = k.includes('[]');
    const baseKey = isCompositeKey ? k.split('[')[0] : k;

    if (!urlParamsWhitelist.includes(baseKey)) {
      console.debug('kiking %s (base : %s)', k, baseKey);
      return;
    }

    if (
      !Object.hasOwnProperty.call(stateData, baseKey) &&
      !Object.hasOwnProperty.call(stateData.filters, baseKey)
    ) {
      console.info('Key %s not found in state data', baseKey);
      return;
    }

    if (isArrayKey) {
      // console.debug('Key %s is an array', baseKey);
      if (Object.hasOwnProperty.call(stateData.filters, baseKey)) {
        // console.debug('Present in filters');
        data.filters[baseKey].push(formattedValue);
      } else {
        // console.debug('Present in state');
        data[baseKey].push(formattedValue);
      }
    } else if (isCompositeKey) {
      // console.debug('Key "%s" is composite', baseKey);
      // const compositeKeyChain = /(\[[a-z_-]\])+/.exec(k);
      const reg = /(\[[0-9a-z_-]+])+/gi;
      let compositeKeyChain = reg.exec(k)?.slice() ?? [];
      compositeKeyChain = compositeKeyChain?.length
        ? compositeKeyChain
            .slice(1, compositeKeyChain.length)
            .map((w) => w.replace('[', '').replace(']', ''))
        : compositeKeyChain;

      // Set value deep inside
      if (Object.hasOwnProperty.call(data.filters, baseKey)) {
        data.filters[baseKey][compositeKeyChain[0]] = formattedValue;
      } else {
        data[baseKey][compositeKeyChain[0]] = formattedValue;
      }
    } else if (Object.hasOwnProperty.call(data.filters, baseKey)) {
      // console.debug('Key %s is contained in the filters', baseKey);
      data.filters[baseKey] = formattedValue;
    } else {
      // console.debug('Default, assigning %s value %s to data', baseKey, formattedValue);

      // We may have numeric values. Cheating for now
      if (['limit', 'page'].includes(baseKey)) {
        formattedValue = Number.parseInt(v, 10);
      }

      data[baseKey] = formattedValue;
    }
  });

  return data;
}
