import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    purchase: null,
    product: null,
    options: null,
    configuration: null,
    paymentConfig: null,
    paymentIntent: null,
    paymentMethods: null,
  },
  actions,
  mutations,
  getters,
};
