import Select2 from 'v-select2-component';

export default {
  components: {
    'vue-select': Select2,
  },
  computed: {
    options() {
      if (!this.contentMap) {
        // eslint-disable-next-line no-console
        console.warn('Select2Component options does not exists');
        return [];
      }

      let formattedPreferredContent;
      if (this.preferredContentMap) {
        formattedPreferredContent = this.formatDataForSelect2(this.preferredContentMap);
      } else {
        // If preferredContentMap is not defined, extract it based on full map and preferred content keys
        formattedPreferredContent = this.formatDataForSelect2(
          Object.keys(this.contentMap)
            .filter((key) => this.preferredContentArray.includes(key))
            .reduce((obj, key) => {
              obj[key] = this.contentMap[key];
              return obj;
            }, {})
        );
      }
      const formattedOtherContent = this.formatDataForSelect2(
        this.contentMap,
        this.preferredContentArray
      );

      return [{ children: formattedPreferredContent }, { children: formattedOtherContent }];
    },
  },

  methods: {
    formatDataForSelect2(obj, filterKeys = []) {
      return Object.keys(obj)
        .filter((k) => (filterKeys.length ? !filterKeys.includes(k) : true))
        .map((k) => ({
          id: k,
          text: obj[k],
        }));
    },
  },
};
