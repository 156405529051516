import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    id: null,
    isLoading: false,
    user: null,
    contact: null,
    error: null,
  },
  actions,
  getters,
  mutations,
};
